import axios from "@/axios";
import {
    loadLanguageAsync,
} from "@shared/plugins/i18n";

export const LangStore = {
    namespaced: true,

    state: {
        current_lang: null, // current language
        user_lang_list: [],
        lang_options: [], // laguage available options
    },

    mutations: {
        SET_CURRENT_LANG(state, payload = null) {
          state.current_lang = payload;
        },
        SET_USER_LANG_LIST(state, payload = []) {
          state.user_lang_list = payload;
        },
        SET_LANG_OPTIONS(state, payload = []) {
          state.lang_options = payload;
        },
    },

    getters: {
        getCurrentLang: (state) => state.current_lang,
        getUserLangList: (state) => state.user_lang_list,
        getLangOptions: (state) => state.lang_options,
    },

    actions: {
        async setLang({ commit }, lang = null) {
            if (lang?.code) {
                // load translations from i18n plugin:
                await loadLanguageAsync(lang.code);
            }
            commit("SET_CURRENT_LANG", lang);
        },
        async fetchUserLang({ dispatch, commit }) {
            const { data } = await axios.get("user/language-preference");

            await dispatch("setLang", data.language_preference);

            commit("SET_USER_LANG_LIST", data.languages);
        },
        async fetchLangOptions({ commit, state }) {
            // no need to refetch the whole list each time:
            if (state.lang_options?.length) return;
            
            const { data } = await axios.get("data/languages");
            commit("SET_LANG_OPTIONS", data.languages);
        },

        async setUserLang({ dispatch }, lang = null) {
            if (lang?.id) {
                await axios.put("user/language-preference", {
                    language_id: lang.id,
                });
            }
            await dispatch("setLang", lang);
        },

        async fetchClientLangList(_, clientId) {
            const { data } = await axios.get(`client/${clientId}/languages`);
            return data;
        },
    },
};