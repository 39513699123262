import { clone } from "lodash";
import axios from "@/axios";
import osintAxios from "@shared/light-ray/axios/osint-axios";
import { isDateWithinInterval } from "../vLex/utils";
import { VLEX_SCREENS, DEFAULT_PAGINATION } from "../vLex/constants";

export const VLexStore = {
  namespaced: true,

  state: {
    requestPayload: {},
    requestHeaders: {},
    searchResults: [],
    cardResults: [],
    requestId: null,
    query: null,
    selectedCardId: null,
    document: null,
    filters: {
      query: "",
      jurisdiction: [],
      docketNumber: "",
      date: {
        start: "",
        end: ""
      }
    },
    filtersOptions: {
      jurisdiction: [],
      docketNumber: [],
    },
    selectedCardStatus: "",
    currentScreen: VLEX_SCREENS.Form,
    paginationData: clone(DEFAULT_PAGINATION),
    pageNumbers: [],
    searchOptions: {},
    isFiltersApplied: false,
    isLoading: false,
    isDocLoading: false,
    isCardLoading: false,
    isRunAllQueries: false,
  },

  mutations: {
    SET_SCREEN(state, payload) {
      state.currentScreen = payload;
    },
    SET_REQUEST_ID(state, payload) {
      state.requestId = payload;
    },
    SET_SEARCH_RESULTS(state, payload) {
      state.searchResults = payload;
    },
    SET_CARD_RESULTS(state, payload) {
      state.cardResults = payload;
    },
    SET_PAGINATION(state, payload) {
      state.paginationData = payload;
    },
    SET_REQUEST_PAYLOAD(state, payload) {
      state.requestPayload = payload;
    },
    SET_REQUEST_HEADERS(state, payload) {
      state.requestHeaders = payload;
    },
    SET_SELECTED_CARD_ID(state, payload) {
      state.selectedCardId = payload;
    },
    SET_SELECTED_CARD_STATUS(state, payload) {
      state.selectedCardStatus = payload;
    },
    SET_DOCUMENT(state, payload) {
      state.document = payload;
    },
    SET_SEARCH_OPTIONS(state, payload) {
      state.searchOptions = payload;
    },
    SET_QUERY(state, payload) {
      state.query = payload
    }, 
    SET_FILTERS(state, payload) {
      state.filters = payload;
    },
    SET_FILTER_PARAM(state, { field, value }) {
      state.filters[field] = value;
    },
    SET_FILTERS_OPTIONS(state, { field, value }) {
      state.filtersOptions[field] = value;
    },
    SET_FILTERS_APPLIED(state, payload) {
      state.isFiltersApplied = payload;
    },
    TOGGLE_LOADING(state) {
      state.isLoading = !state.isLoading;
    },
    TOGGLE_DOC_LOADING(state) {
      state.isDocLoading = !state.isDocLoading;
    },
    TOGGLE_CARD_LOADING(state) {
      state.isCardLoading = !state.isCardLoading;
    },
    SET_IS_RUN_ALL_QUERIES(state, payload) {
      state.isRunAllQueries = payload;
    }
  },

  getters: {
    getCurrentScreen: state => state.currentScreen,
    getSearchResults: state => state.searchResults,
    getSearchResultsByStatus: (state, getters) => {
      const { getSearchResults, getCardDetailsMap } = getters;

      switch (state.selectedCardStatus) {
        case "all":
          return getSearchResults;
        case "confirmed":
          return getSearchResults
            .filter(el => el.doc_id in getCardDetailsMap && getCardDetailsMap[el.doc_id]?.status === "CONFIRMED");
        case "potential":
          return getSearchResults
            .filter(el => el.doc_id in getCardDetailsMap && getCardDetailsMap[el.doc_id]?.status === "POTENTIAL");
        case "irrelevant":
          return getSearchResults
            .filter(el => el.doc_id in getCardDetailsMap && getCardDetailsMap[el.doc_id]?.status === "IRRELEVANT");
        case "unknown":
          return getSearchResults
            .filter(el => el.doc_id in getCardDetailsMap && getCardDetailsMap[el.doc_id]?.status === "None");
        default:
          return getSearchResults;
      }
    },
    getFilteredSearchResults: (state, getters) => {
      let result = [...getters.getSearchResultsByStatus];
      const { query, jurisdiction, date, docketNumber } = state.filters;

      if (query.length > 0) {
        result = result
          .filter(el => el?.data?.title?.toLowerCase().includes(query.toLowerCase()));
      }

      if (jurisdiction.length > 0) {
        result = result
          .filter(el => jurisdiction.includes(el?.data?.jurisdiction?.id));
      }

      if (date.start && date.end) {
        result = result
          .filter(el => isDateWithinInterval(el?.data?.meta?.["Judgment Date"], date));
      }

      if (docketNumber?.length > 0) {
        result = result
          .filter(el => el?.data?.meta?.["Docket Number"] === docketNumber);
      }

      return result;
    },
    paginatedSearchResults: (state, getters) => {
      const { perPage, page } = state.paginationData;
      if (state.isRunAllQueries) {
        return getters.getFilteredSearchResults.slice(perPage * (page - 1), page * perPage);
      }

      return  getters.getFilteredSearchResults;
    },
    getPaginationData: state => state.paginationData,
    getTotalPages: (state) => Math.ceil(state.paginationData.total_count / state.paginationData.perPage),
    getSelectedCardId: state => state.selectedCardId,
    getSelectedCard: (state, getters) => getters.getSearchResults.find(el => el.data.id === state.selectedCardId) ?? {},
    getDocument: state => state.document,
    getRequestPayload: state => state.requestPayload,
    getSearchOptions: state => state.searchOptions,
    getQuery: (state) => state.query,
    getRequestId: (state) => state.requestId,
    getCardDetailsMap: (state) =>  {
      return state.cardResults.reduce((acc, curr) => {
        if ("card_id" in curr) {
          acc[curr?.card_id] = curr;
        }
        return acc;
      }, {});
    },
    getFilters: state => state.filters,
    getFiltersOptions: state => state.filtersOptions,
    getIsFiltersApplied: state => state.isFiltersApplied,
    getIsRunAllQueries: state => state.isRunAllQueries,
    getIsLoading: state => state.isLoading,
    getIsDocLoading: state => state.isDocLoading,
    getIsCardLoading: state => state.isCardLoading,
    getFilteredCardResults: (state, getters) => {
      // return state.cardResults.filter(el => Boolean(getters.getSearchResults.find(r => r?.doc_id === el?.card_id))) ?? []
      const dataSource = state.isFiltersApplied ? getters.getFilteredSearchResults : getters.getSearchResults
      return state.cardResults.filter(el => Boolean(dataSource.find(r => r?.doc_id === el?.card_id))) ?? []
    },
    getCardStatusCount: (state, getters) => {
      const dataSource = state.isFiltersApplied ? getters.getFilteredSearchResults : getters.getSearchResults
      return {
        all: dataSource.length,
        confirmed: getters.getFilteredCardResults.filter((res) => res?.status === 'CONFIRMED').length,
        unknown: getters.getFilteredCardResults.filter((res) => res?.status === 'UNKNOWN' || !res?.status || res?.status === 'None').length,
        potential: getters.getFilteredCardResults.filter((res) => res?.status === 'POTENTIAL').length,
        irrelevant: getters.getFilteredCardResults.filter((res) => res?.status === 'IRRELEVANT').length,
      }
    }
  },

  actions: {
    handleVLexSearch: async ({ commit, dispatch, state }) => {
        commit("TOGGLE_LOADING");
        commit("SET_SCREEN", VLEX_SCREENS.Results);

        try {
          const { data } = await osintAxios.post("/api/v1/litigation/search_sources", {
            ...state.requestPayload,
            per_page: state.paginationData.perPage,
            page: state.paginationData.page,
            sources: ["vlex"],
          }, { headers: state.requestHeaders });
  
          if (data?.request_id) {
            commit("SET_REQUEST_ID", data.request_id);
            await dispatch("searchByRequestId");
            commit("TOGGLE_LOADING");
          }
        } catch(e) {
          console.log(e);
        }
      },

      searchByRequestId: async({ commit, state }) => {
        try {
          const result = await osintAxios.post("/api/v1/vlex/search", {
            ...state.requestPayload,
            per_page: state.paginationData.perPage,
            page: state.paginationData.page,
            request_id: state.requestId,
          }, { headers: state.requestHeaders });

          if (result?.data) {
            commit("SET_SEARCH_RESULTS", result.data.data);
            commit("SET_PAGINATION", {
              ...state.paginationData,
              total_count: result.data.total_count
            });

          }
        } catch(e) {
          console.log(e);
        }
      },

      fetchDocument: async({ commit, state, getters }) => {
        commit("TOGGLE_DOC_LOADING");
        try {
          const { data } = await osintAxios.post("/api/v1/vlex/document/_id", {
            vlex_id: getters.getSelectedCard.data.id,
            request_id: state.requestId,
            doc_id: getters.getSelectedCard.doc_id,
          }, { headers: state.requestHeaders });

          if (data) {
            commit("SET_DOCUMENT", data.data);
            
          }
        } catch(e) {
          console.log(e);
        } finally {
          commit("TOGGLE_DOC_LOADING");
        }
      },

      updateCard: async({ dispatch, commit }, payload) => {
        commit("TOGGLE_CARD_LOADING")

        try {
          const { data } = await axios.post("/service/upsert-card-result", payload);

          if (data?.data) {
            await dispatch("fetchCardResults", {
              tool_name: payload.tool_name,
              query_id: payload.query_id,
              entity_id: payload.main_entity_id
            });

            return data.status
          } 
        } catch(e) {
          console.log(e);
        } finally {
          commit("TOGGLE_CARD_LOADING");
        }
      },

      fetchCardResults: async({ commit, state, rootGetters }, payload) => {
        try {
          const { data } = await axios.post("/service/card-results", {
            case_id: rootGetters.getNeoCaseId,
            query_id: state.requestId,
            ...payload
          });

          if (data?.data) {
            commit("SET_CARD_RESULTS", data.data);
          }
        } catch(e) {
          console.log(e);
        }
      },

      resetFilters: ({ commit,  }) => {
        commit("SET_FILTERS", {
          query:  "",
          jurisdiction: [],
          docketNumber: "",
          date: {
            start: "",
            end: "",
          }
        });
        commit("SET_FILTERS_APPLIED", false);
        commit("SET_SELECTED_CARD_ID", null);
      },

      resetPagination: ({ commit }) => {
        commit("SET_PAGINATION", clone(DEFAULT_PAGINATION));
      },

      runSelectedQuery: async ({ commit, dispatch }, payload) => {
        commit("SET_QUERY", payload);
        commit("SET_REQUEST_PAYLOAD", payload.query);
        commit("TOGGLE_LOADING");
        dispatch("handleVLexSearch")
        commit("TOGGLE_LOADING");
      },

      setResultsScreen({ commit }) {
        commit("SET_SCREEN", VLEX_SCREENS.Results);
      },

      resetState: ({ commit, dispatch }) => {
        commit("SET_REQUEST_PAYLOAD", {});
        commit("SET_REQUEST_HEADERS", {});
        commit("SET_SEARCH_RESULTS", []);
        commit("SET_DOCUMENT", null);
        commit("SET_REQUEST_ID", null);
        commit("SET_SELECTED_CARD_ID", null);
        commit("SET_SCREEN", VLEX_SCREENS.Form);
        dispatch("resetPagination");
        dispatch("resetFilters");
      }
    },
};
